import React from 'react';
import { Link } from 'react-router-dom';
import './AdminPanel.css';

const AdminPanel = () => {
  return (
    <div className="adminPanel-container">
      <h1>Panel de Control</h1>
      <div className="adminPanel-linkWrapper">
        <Link to="/local" className="adminPanel-linkButton adminPanel-physicalSalesBtn">Ventas físicas</Link>
        <Link to="/gestion-stock" className="adminPanel-linkButton adminPanel-stockManagementBtn">Gestion Stock</Link>
        <Link to="/subscriptores" className="adminPanel-linkButton adminPanel-subscribersBtn">Lista de Suscriptores</Link>
        <Link to="/editor-masivo" className="adminPanel-linkButton adminPanel-massEditorBtn">Editor masivo</Link>
        <Link to="/configuracion-descuentos" className="adminPanel-linkButton adminPanel-discountConfigBtn">Configurar descuentos</Link>
        <Link to="/historial-ventas" className="adminPanel-linkButton adminPanel-salesHistoryBtn">Tickets vendidos</Link>
        <Link to="/metricas" className="adminPanel-linkButton adminPanel-salesHistoryBtn">Metricas</Link>
        <Link to="/grafico" className="adminPanel-linkButton adminPanel-salesHistoryBtn">Grafico</Link>
        <Link to="/free" className="adminPanel-linkButton adminPanel-qrScannerBtn">Free</Link>
        <Link to="/escanear-qr" className="adminPanel-linkButton adminPanel-qrScannerBtn">Scanner QR</Link>
        <Link to="/qr-lista" className="adminPanel-linkButton adminPanel-qrScannerBtn">Ingresos con free</Link>
      </div>
    </div>
  );
};

export default AdminPanel;























import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './QRList.css';

const QRList = () => {
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('used');
  const [modelFilter, setModelFilter] = useState('all');

  useEffect(() => {
    const fetchTickets = async () => {
      setIsLoading(true);
      setError('');
      try {
        const ticketsSnapshot = await getDocs(collection(db, 'FreeTicket'));
        const fetchedTickets = ticketsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTickets(fetchedTickets);
      } catch (error) {
        console.error('Error fetching tickets:', error);
        setError('Error al cargar los tickets. Por favor, intenta nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTickets();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      return dateString;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const getUniqueModels = () => {
    const models = new Set();
    tickets.forEach(ticket => {
      ticket.cartItems.forEach(item => {
        if (item.model) models.add(item.model);
      });
    });
    return Array.from(models);
  };

  const filteredTickets = tickets.flatMap(ticket =>
    ticket.cartItems.filter(item => {
      const usageMatch = 
        filter === 'all' ? true :
        filter === 'used' ? item.isUsed :
        !item.isUsed;
      
      const modelMatch = 
        modelFilter === 'all' ? true :
        item.model === modelFilter;

      return usageMatch && modelMatch;
    })
  ).sort((a, b) => {
    if (!a.isUsed || !b.isUsed) return 0;
    return a.usedTimestamp.localeCompare(b.usedTimestamp);
  });

  return (
    <div className="qr-list-container">
      <h2>Lista de Entradas Free</h2>
      {isLoading ? (
        <p className="loading-message">Cargando tickets...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : tickets.length === 0 ? (
        <p>No hay tickets generados.</p>
      ) : (
        <>
          <div className="qr-list-filters">
            <div className="filter-group">
              <label htmlFor="usage-filter">Estado: </label>
              <select
                id="usage-filter"
                value={filter}
                onChange={e => setFilter(e.target.value)}
                className="filter-select"
              >
                <option value="all">Todos</option>
                <option value="used">Usados</option>
                <option value="unused">No usados</option>
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="model-filter">Evento: </label>
              <select
                id="model-filter"
                value={modelFilter}
                onChange={e => setModelFilter(e.target.value)}
                className="filter-select"
              >
                <option value="all">Todos</option>
                {getUniqueModels().map(model => (
                  <option key={model} value={model}>{model}</option>
                ))}
              </select>
            </div>
          </div>

          <table className="qr-list-table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Usado</th>
                <th>Hora de Escaneo</th>
              </tr>
            </thead>
            <tbody>
              {filteredTickets.map(item => (
                <tr key={item.qrValue}>
                  <td>{item.nombre}</td>
                  <td className={item.isUsed ? 'used' : 'unused'}>
                    {item.isUsed ? 'Sí' : 'No'}
                  </td>
                  <td>{item.isUsed ? formatDate(item.usedTimestamp) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default QRList;